.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e7eb;
  height: 100vh;
}

.login-form {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-title {
  color: #013cc6;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.error-message {
  color: red;
}

.login-image {
  display: none;
}

@media (min-width: 768px) {
  .login-image {
    display: block;
    flex: 1;
    background-color: #f0f2f5;
    padding: 40px;
    text-align: center;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Update the CSS for the popup */
.change-password-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1000; /* Set a higher z-index value */
}
.canvasjs-chart-credit {
  display: none !important;
}

/* Update the CSS for the search bar */
.search-bar {
  z-index: 900; /* Lower z-index value than the popup */
}

.numbers-card {
  position: relative;
}

.lock-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
