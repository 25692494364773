.custom-date-range-picker {
  .rdrDateRangePickerWrapper {
    width: 100%;
  }

  .rdrCalendarWrapper {
    .rdrMonths {
      .rdrMonth {
        width: 100%;
        height: auto;
      }
    }
  }

  .rdrMonthAndYearPickers select {
    font-size: 12px;
  }

  .rdrMonthAndYearWrapper {
    height: 30px;
  }
}

.statistics-container {
  padding: 5px;
  width: 100%; /* Ensure it uses the full width available */
}
.statistics-sidebar {
  width: 100%; /* Default width */


}
@media (max-width: 767px) {
 
  .rdrDefinedRangesWrapper{
  max-width: 90px !important; /* Width for larger screens */

  font-size: xx-small;
  margin-right: -5 !important;
  }
  .rdrDateDisplayWrapper{
    max-width: 70% !important;; /* Width for larger screens */

  }
}
@media (max-width: 767px) {
  .custom-date-range-picker {
    .rdrDateRangePickerWrapper {
      width: 70%;
      
    }

    .rdrCalendarWrapper {
      .rdrMonths {
        .rdrMonth {
          width: 70%;
          height: auto;
       
        }

      }
    }

    .rdrMonthAndYearPickers select {
      font-size: 10px;
      width: 90%;

    }

    .rdrMonthAndYearWrapper {
      height: 25px;
      width: 70%;
      
    }
  }

  .statistics-container {
    padding: 0;
    width: 100%;
     /* Adjust width to fit smaller screens */
  }

  .ant-layout-content {
    padding: 0 1px;
  
    
  }

}
